


























































import Vue from "vue";
import funclient from "@/plugins/funclient";
import labels from "@/utils/ResultFieldLabels";

export default Vue.extend({
  name: "Confirmation",
  data() {
    return {
      isLoading: true,
      transaction: {},
    };
  },
  computed: {
    transactionKeys() {
      const keys = Object.keys(labels);
      return keys.filter((i) => {
        return (this as any).transaction[i] !== ""
      })
    },
    getIcon() {
      if ((this as any).transaction.x_cod_respuesta === '1') {
        return 'check-circle'
      }
      if ((this as any).transaction.x_cod_respuesta === '3') {
        return 'pause-circle'
      }
      return 'exclamation-circle'
    }
  },
  methods: {
    getLabel(key: string): string {
      return labels[key];
    },
    print() {
      window.print();
    }
  },
  async mounted() {
    await this.$recaptchaLoaded()
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
    if (this.$route.params.transactionId) {
      const transactionFut = await funclient.post("getTransaction", {transactionId: this.$route.params.transactionId}, {})
      this.transaction = transactionFut.data
      this.isLoading = false;
    }
  }
});
