import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/analytics"

const firebaseConfig = {
  apiKey: "AIzaSyArtLLd8EPtlyXnM5zkc-x9_mzOjxuvH8w",
  authDomain: "eventos-fitness-95a01.firebaseapp.com",
  projectId: "eventos-fitness-95a01",
  storageBucket: "eventos-fitness-95a01.appspot.com",
  messagingSenderId: "280734825248",
  appId: "1:280734825248:web:85f40a4a593a0d5d253545"
};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebaseApp.auth();
const analytics = firebaseApp.analytics();

export { firebase, db, auth, analytics };
