








































import Vue from "vue";
import {db} from '@/plugins/firebase';

export default Vue.extend({
  name: "Confirmation",
  data() {
    return {
      transaction: {}
    }
  },
  async mounted() {
    this.transaction = await db.collection('transacciones').doc(this.$route.query.transactionId as string).get()
  }
});
