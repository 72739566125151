import "mutationobserver-shim";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "@/plugins/bootstrap-vue.ts";
import "@/plugins/fontawesome.ts";
import "@/plugins/recaptcha";
import "@/plugins/scrollTo";
import "@/plugins/firebase";
import funclient from "./plugins/funclient";

import './styles/styles.scss'

Vue.prototype.$funclient = funclient;
Vue.config.productionTip = false;

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
