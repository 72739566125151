




























import Vue from "vue";

const BannerSection = () => {
  return import("@/components/BannerSection.vue");
};
const PageHeader = () => {
  return import("@/components/PageHeader.vue");
};
const PageFooter = () => {
  return import("@/components/PageFooter.vue");
};
const GoogleMap = () => {
  return import("@/components/GoogleMap.vue");
};
const RegistrationForm = () => {
  return import("@/components/RegistrationForm.vue");
};
// const ContactForm = () => {
//   return import("@/components/ContactForm.vue");
// };
const SpeakerComponent = () => {
  return import("@/components/SpeakerComponent.vue");
};
const AboutSection = () => {
  return import("@/components/AboutSection.vue");
}
const PricingComponent = () => {
  return import("@/components/PricingComponent.vue");
}
const FaqComponent = () => {
  return import("@/components/Faq.vue");
}
const TermsComponent = () => {
  return import("@/components/TermsOfService.vue");
}
const PrivacyComponent = () => {
  return import("@/components/Privacy.vue");
}

export default Vue.extend({
  components: {
    PageHeader,
    PageFooter,
    BannerSection,
    GoogleMap,
    RegistrationForm,
    // ContactForm,
    SpeakerComponent,
    AboutSection,
    PricingComponent,
    TermsComponent,
    PrivacyComponent,
    FaqComponent,
  },
  computed: {
    modalTitle(): string {
      switch (this.currentModal) {
        case 'faq':
          return "Preguntas Frecuentes";
        case 'terms':
          return "Términos y Condiciones del Servicio";
        case 'privacy':
          return "Política de Privacidad y Tratamiento de Datos";
      }
      return "";
    }
  },
  data() {
    return {
      currentModal: "",
    }
  },
  methods: {
    openModal(url: string) {
      this.currentModal = url;
      this.$bvModal.show('page-modal');
    }
  },
  async mounted() {
    await this.$recaptchaLoaded()
    const recaptcha = this.$recaptchaInstance;
    recaptcha.hideBadge();
  }
});
