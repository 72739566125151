import axios, { AxiosResponse } from "axios";

const baseUrl = '/api/';

const funclient = {
  async post(name: string, args: Record<string, unknown>, data: Record<string, unknown>) : Promise<AxiosResponse> {
    const res = await this.call("post", name, args, data);
    return res;
  },
  async call(
    method: string,
    name: string,
    args: Record<string, unknown> = {},
    data: Record<string, unknown> = {}
  ): Promise<AxiosResponse> {
    const requestHeaders = {
      headers: {
        Authorization: "Content-Type: application/json",
      },
    };
    switch (method.toLowerCase()) {
      case "get":
        return axios.get(baseUrl + name, { params: args, ...requestHeaders });
      case "post":
        return axios.post(baseUrl + name, data, {
          params: args,
          ...requestHeaders,
        });
    }
    throw "Invalid HTTP method";
  },
}

export default funclient;