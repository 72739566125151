import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Confirmation from '../views/Confirmation.vue'
import Response from '../views/Response.vue'
import Home from '../views/Home.vue'
import AdminHome from '../views/AdminHome.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/confirmation/:transactionId',
    name: 'confirmation',
    component: Confirmation
  },
  {
    path: '/response/:transactionId',
    name: 'response',
    component: Response
  },
  {
    path: '/admin',
    name: 'admin',
    component: AdminHome
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
