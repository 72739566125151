
















































import { firebase, auth } from "@/plugins/firebase.ts";
import funclient from "@/plugins/funclient.ts";

export default {
  name: "AdminHome",
  data(): Record<string, any> {
    return {
      sortBy: "createdAt",
      sortDesc: true,
      loggedIn: false,
      currentUser: null,
      token: "",
      leads: [],
      fields: [
        { key: "id", sortable: true },
        { key: "name", sortable: true },
        { key: "email", sortable: true },
        { key: "phone", sortable: false },
        { key: "message", sortable: false },
        { key: "createdAt", sortable: true },
      ],
    };
  },
  async mounted(): Promise<void> {
    await (this as any).$recaptchaLoaded();
    const recaptcha = (this as any).$recaptchaInstance;
    recaptcha.hideBadge();
    auth.onAuthStateChanged( async (user) => {
      if (user) {
        (this as any).currentUser = user;
        (this as any).loggedIn = true;
        (this as any).token = await auth.currentUser?.getIdToken(true);
        (this as any).getRecords();
      }
    });
  },
  methods: {
    async getRecords() {
      try {
        (this as any).leads = (await funclient.post("get-records", {}, { token: (this as any).token })).data;
      } catch (err) {
        this.logout();
      }
    },
    async login(): Promise<void> {
      try {
        const provider = new firebase.auth.GoogleAuthProvider();
        const res = await auth.signInWithPopup(provider);
      } catch (err: any) {
        console.error(err);
      }
    },
    async logout(): Promise<void> {
      await auth.signOut();
      (this as any).loggedIn = false;
      (this as any).currentUser = null;
    },
  },
};
