const labels: Record<string, string> = {
  x_tax: "Impuestos",
  x_extra1: "Extra 1",
  x_extra2: "Extra 2",
  x_extra3: "Extra 3",
  x_extra4: "Extra 4",
  x_extra5: "Extra 5",
  x_extra6: "Extra 6",
  x_extra7: "Extra 7",
  x_extra8: "Extra 8",
  x_extra9: "Extra 9",
  x_extra10: "Extra 10",
  x_respuesta: "Respuesta",
  x_bank_name: "Banco",
  x_fecha_transaccion: "Fecha",
  x_approval_code: "Código de Aprobación",
  x_currency_code: "Moneda",
  x_id_factura: "No. Recibo",
  x_customer_country: "País",
  x_customer_movil: "Teléfono",
  x_customer_name: "Nombre",
  x_customer_ind_pais: "Indicativo País",
  x_amount_base: "Base para Impuestos",
  x_cod_respuesta: "Código de Respuesta",
  x_customer_ip: "Dirección IP",
  x_amount_ok: "Monto Total",
  x_response_reason_text: "Mensaje",
  x_description: "Descripción",
  x_ref_payco: "Ref. ePayco"
}

export default labels;